import React, {Component} from 'react';
import TextBlock from './blocks/text';
import AuthorQuote from './blocks/authorquote';
import ImageBlock from './blocks/image';
import GalleryBlock from './blocks/coregallery';
import CoverBlock from './blocks/corecover';
import VerseBlock from './blocks/coreverse';
import MediaTextBlock from './blocks/coremediatext';
import SeparatorBlock from './blocks/coreseparator';
import PullQuoteBlock from './blocks/corepullquote';
import ImageCoreBlock from './blocks/coreimage';


export default class Blocks extends Component {
    renderBlocks(item) {
        //var idCount=0;
        //console.log(item);
        const {blockName} = item;
        //idCount++;
			switch (blockName) {
                case "lazyblock/text" :
                    return (
                            <TextBlock params={item.innerHTML} />

                    )

                case "core/image" :
                    return (
                            <ImageCoreBlock params={item.innerHTML} />
    
                    )    

                case "lazyblock/authorquote" :
                    return (
                            
                             <AuthorQuote text={item.attrs.text} author={item.attrs.author} />
                            
                        )
   
                case "lazyblock/image" :
                        return (
                                
                                <ImageBlock text={item.attrs.text} image={item.attrs.image} align={item.attrs.align}  />
                                
                            )

                case "core/gallery" :
                        return (
                                    
                                <GalleryBlock text={item.innerHTML} align={item.attrs.align}  />
                                    
                                )

                case "core/cover" :
                        return (
                                        
                                <CoverBlock content={item.innerHTML} align={item.attrs.align} text={item.innerBlocks[0].innerHTML}  />
                                        
                                )

                case "core/verse" :
                        return (
                                        
                                <VerseBlock text={item.innerHTML}   />
                                        
                                )
                case "core/pullquote" :
                        return (
                                                        
                                <PullQuoteBlock text={item.innerHTML}   />
                                                        
                                )                

                case "core/separator" :
                        return (
                                            
                                <SeparatorBlock text={item.innerHTML}  />
                                            
                                )

                case "core/media-text" :
                        return (
                                            
                                <MediaTextBlock  content={item.innerHTML} align={item.attrs.align} text={item.innerBlocks[0].innerHTML}  />
                                            
                                )

                case null:
                        break;                                                       
                default: 

                    return (
         
                            <TextBlock params={item.innerHTML} />

                    )
                        
            }
    }

    render() {
       
        const blocks = this.renderBlocks(this.props.params);
        const blockClass="block-width-"+this.props.params.attrs.align;
        return (
            <div className={blockClass}>
                {blocks}
            </div>
        );
    }

}
