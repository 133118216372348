import React from 'react';
import NotFound from './404';

const ErrorMessage = (props) => {
    console.log(props);
    if (props.code==='404') {
        return (
            <NotFound />    
        )
    }
    else {
        return (
            
            <div>
                404
            </div>
        )
    }
}

export default ErrorMessage;