import React from 'react';
import LoadPage from './loadPage';
import Menu from './menu';
import Swipe from './swipe';
import logo from '../images/logo.png';
import spineBg from '../images/spine.jpg';
import menuBg from '../images/menuBg.jpg';
import TrackVisibility from 'react-on-screen';
import Teaser from './teaser';


import Paginator from './paginator';


function Page(props) {
    //console.log(props.match.url);
    //const {foo} = props.location.state;
    //console.log(foo);
    const ComponentToTrack = ({ isVisible, params }) => {
        const visClass = isVisible ? 'paginator showblock' : 'paginator hideblock';            
        
    return <div className={visClass}><Teaser /><Paginator />{/*<Teaser />*/}</div>;
    }

    function handleClick(e) {
        document.querySelector('.container').classList.toggle("menuopen"); 
        //console.log('The link was clicked.');
    }
    
    return (

        <div className="container">
            <div className="textpanel colRight">

                    <div className="textfield">            
                        <LoadPage page={props.match.url} />
                        <TrackVisibility  partialVisibility>
                                    <ComponentToTrack  />
                        </TrackVisibility>
                        
                    </div>  
                    
        
            </div>
            <div className="shadowpanel">
            </div>
            <div className="menupanel"  style={{backgroundImage:`url(${menuBg})`}}> 
                
                <div className="touchmenu"  style={{backgroundImage:`url(${spineBg})`}}>
                    <div className="logo">
                        <img src={logo} alt="Альбом «Медведь в истории культуры»" />
                    </div>                    
                    <Swipe>
                        <div className="clickmenu" id="swipeLink" onClick={handleClick}>Содержание</div>
                    </Swipe>     
                </div>
                <div className="menulist">
                    <Menu />
                </div>
            </div>        
        </div>
    )
}

export default Page;

