import React from 'react';
import './main.min.css';
import Contents from './components/contents';
import Page from './components/page';
//import ScriptLoader from './components/loadscript';
import {BrowserRouter as Router, Route} from 'react-router-dom';

import { ParallaxProvider } from 'react-scroll-parallax';
//import Teaser from './components/teaser';
import {Helmet} from "react-helmet";
import { YMInitializer } from 'react-yandex-metrika';



function App() {
  return (
    <ParallaxProvider>

      <Router>
      <Helmet>
          <meta charSet="utf-8" />
          <title>Книга «Медведь в истории культуры» — читать бесплатно онлайн</title>
          <link rel="canonical" href="https://bear.senator-perm.ru" />
      </Helmet>
      <div className="App viewport">    
          
          
            <Route path='/' component={Contents} exact />
            <Route path='/:page' component={Page} exact /> 
            <Route path='/:page/:page' component={Page} />           

         
          
      </div>
     
      <YMInitializer accounts={[58761211]} />   
      </Router>
      
    </ParallaxProvider>
  );
}

export default App;
