import React from 'react';


export default function VerseBlock(params) {
    function createMarkup(htmlContent) {
         return {__html: htmlContent};
    };
    //console.log(params);
    const blockClass="verse "+params.align;

    return (
        <div className="blockEntry">            
            <div className={blockClass} dangerouslySetInnerHTML={createMarkup(params.text)} />
        </div>
    )
}