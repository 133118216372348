import React, {Component} from 'react';
import WPService from '../services/wp-service';
import ErrorMessage from './error';
//import Loader from './loader';
import { Link } from 'react-router-dom';





export default class MenuLoader extends Component {
	WPService = new WPService();
	
	state = {
		menu: [],
		error: false,
		loading: true
	}
	
	componentDidMount() {		
		this.updateMenu();
	}

	updateMenu() {
		this.WPService.getMenu()
		.then(this.onMenuLoaded)
		.catch(this.onError);
		

	}
	
	onMenuLoaded = (menu) => {
		this.setState({
			menu, /*menu: menu только сокращенная запись*/
			error: false,
			loading: false
		});
	}

	onError = (err) => {
		this.setState({ /* в данном случае, setState изменит только одно свойство - поменяет error*/
			error: true
		})
	}

	renderItems(arr) {
		

		return arr.map(item => {
			const {title, object_id, url} = item;
			const base= 'https://www.senator-perm.ru/bear/';
			const slug = '/'+url.replace(base, '');

			//console.log(slug);
			//console.log('pathname '+window.location.pathname);

			let cl="menuItem";

			const closeMenu = () => {
				if (document.querySelector('.container')) {
					document.querySelector('.container').classList.remove("menuopen");
				}
			}

			if (slug.toUpperCase()===window.location.pathname.toUpperCase()) {
				cl="activeItem";
			}
			

			

			return (
				<li key={object_id} className={cl}>	
								
					<Link to={slug} onClick={closeMenu}>{title}</Link>
				</li>
			)
		});
	}

    render() {
		const {error, menu, loading} = this.state;
		
		if (error) {
		return <ErrorMessage/> /*Если в скрипте встречается return - работа скрипта останавливается. В данном случае, если error = true */
		}

		if (loading) {
            return <div>Загрузка меню...</div>
        }

		const items = this.renderItems(menu);
		return (
			<ul className="left">
				{items}
			</ul>
		)
	}
}