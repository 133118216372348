import React, {Component} from 'react';
import WPService from '../services/wp-service';
import Blocks from './blocks'
import ErrorMessage from './error';
import Loader from './loader';
import { ParallaxBanner } from 'react-scroll-parallax';
import bgshadow from '../images/bg-shadow.png';
import load from '../images/loader.gif';
import Orphans from './orphans';
import {Helmet} from "react-helmet";
//import ym from 'react-yandex-metrika';



export default class PageLoader extends Component {
    WPService = new WPService();

	state = {
		posts: [],
		error: false,
        loading: true,
	}

	

	
	
	componentDidUpdate(prevProps) {
		if(prevProps.page !== this.props.page) {
			this.setState({
				loading: true
			})
			this.updatePost(this.props.page);
		}
	}

	
	componentDidMount() {

			this.updatePost(this.props.page);	

		
	}

	updatePost(page) {

		this.WPService.getPost(page) // сюда передать id страницы для загрузки
		.then(this.onPostLoaded)
        .catch(this.onError);
		//console.log('update');
		
	}
	
	onPostLoaded = (posts) => {

		const newLocal = this;
		newLocal.setState({
			posts, /*posts: posts только сокращенная запись*/
			error: false,
			loading: true
		});
		//console.log('component mount. header: ' + posts[0].fimg_url);
		const img = new Image();
		img.src = posts[0].fimg_url;

		if (posts[0].fimg_url) {
			img.onload = function(){
				newLocal.setState({
					loading: false
				});	
			}
			img.onerror = function(){
				newLocal.setState({
					error: false
				});
			}	
		}
		else {
			newLocal.setState({
				error: false,
				loading: false
			});
		}
		
			
        
	}

	onError = (err) => {
		this.setState({ /* в данном случае, setState изменит только одно свойство - поменяет error*/
			error: true
		})
	}




    
    render() {
        const {error, posts, loading} = this.state;

		//console.log('render load page');


		
		

		//console.log('render load page');

		const postNum = 0;
		let code=200;
        
		if (error) {
			if (posts.length<1) {code="404";}
		    return <ErrorMessage code={code}/> /*Если в скрипте встречается return - работа скрипта останавливается. В данном случае, если error = true */
		}

		if (loading) {
            return <Loader />
		}

		posts[postNum].blocks=posts[postNum].blocks.filter((a) => a.blockName !== null);
		
		/* Вывод мета-тэгов YoastSEO */
		const moreMeta = posts[postNum].yoast_meta.map((m, idx) => {
			if (typeof m.name !== 'undefined') {
				return (
					<meta key={idx} name={m.name} content={m.content} />
				);
			}
			if (typeof m.property !== 'undefined') {
				return (
					<meta key={idx} property={m.property} content={m.content} />
				);
			}
			return (
				true
			);

		});

		/* Вывод schema.org от YoastSEO */
		const graphMeta = posts[postNum].yoast_json_ld.map((m, idx) => {
		
			return (
				<script key={idx} type="application/ld+json" class="yoast-schema-graph yoast-schema-graph--main">{JSON.stringify(m)}</script>
				
			);
		});

		//const cortitle=posts[postNum].title.rendered.replace(/\s+(в|без|до|из|к|на|по|о|от|перед|при|через|с|у|и|нет|за|над|для|об|под|про)+\s/ig, " $1\u00a0"); 

		

		if (this.props.header !== "false") {
			
			//console.log(posts[postNum].meta);

			return (

				<article id={"post-"+posts[postNum].id}>
					<Helmet>						
						<title>{posts[postNum].yoast_title}</title>

						{moreMeta}
						{graphMeta}
						
						<link rel="canonical" href={"https://bear.senator-perm.ru"+this.props.page} />
						
						<style type="text/css">{`
							.blocks-gallery-item figure, .image, .wp-block-media-text__media {
								background-image: url('`+load+`');
							}
						`}</style>
						
					</Helmet>
					<header>
							
							<ParallaxBanner
								className="fimg"
								layers={[
									{
										image: posts[postNum].fimg_url,
										amount: 0.5,
									},
	
								]}
								style={{
									height: '40vh',
								}}
							>
							
							</ParallaxBanner>
							<h1><Orphans>{posts[postNum].title.rendered}</Orphans></h1>	
							<div className="mask" style={{backgroundImage:  `url(${bgshadow})`}}></div>
							<div className="bannerShadow"></div>
					</header>
					
					<Blocks arr={posts[postNum].blocks} />
				</article>
		
			);
		}
		else {
			return (
				<article id={"post-"+posts[postNum].id}>
					<Blocks arr={posts[postNum].blocks} />
					<div dangerouslySetInnerHTML={{__html: this.ym}}/>
				</article>	
							
			);
		}
		
		
    }
}