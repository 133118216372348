import React, {Component} from 'react';
import WPService from '../services/wp-service';
import ErrorMessage from './error';
//import Loader from './loader';
//import { Link } from 'react-router-dom';




//Выводим ссылки назад и вперед внизу страницы
export default class Paginator extends Component {
	WPService = new WPService();
	
	state = {
		menu: [],
		error: false,
		loading: true
	}
	
	componentDidMount() {		
		this.updateMenu();
	}

	updateMenu() {
		this.WPService.getMenu()
		.then(this.onMenuLoaded)
		.catch(this.onError);
		

	}
	
	onMenuLoaded = (menu) => {
		this.setState({
			menu, /*menu: menu только сокращенная запись*/
			error: false,
			loading: false
		});
	}

	onError = (err) => {
		this.setState({ /* в данном случае, setState изменит только одно свойство - поменяет error*/
			error: true
		})
	}

	  
    pageNav = (arr) => { //Выводим ссылки назад и вперед внизу страницы
        const base= 'https://www.senator-perm.ru/bear/';

        const showPageLink = (menuElement, direction) => {           
            
            if (typeof menuElement != 'undefined') {
                const slug = '/'+menuElement.url.replace(base, '');
                
                return ( 
                    <div key={direction} className={direction+' pagelink'}>
                        <a href={slug}><span>{menuElement.title}</span></a>
                    </div>
                );
            }    
        }
        
        let links = [];
        arr.forEach(function(element, index) {
            
            
            const slug = '/'+element.url.replace(base, ''); 
            const prev = arr[index-1];
            const next = arr[index+1];
            
            
            if (slug.toUpperCase()===window.location.pathname.toUpperCase()) {
                links.push(showPageLink(prev, 'back'));
                links.push(showPageLink(next, 'forward'));                
            }
            
        });

        return (
            <>
                 {links}
            </>
        );

    }

    render() {
        const {error, menu, loading} = this.state;



        //pageNav(menu);
		
		if (error) {
		return <ErrorMessage/> /*Если в скрипте встречается return - работа скрипта останавливается. В данном случае, если error = true */
		}

		if (loading) {
            return <div>Загрузка навигации...</div>
        }

		const navItems = this.pageNav(menu);
		return (
			<>
				{navItems}
			</>
		)
	}
}