export default class ItemsService {
	constructor() {
		this._apiBase = 'https://www.senator-perm.ru/bear/wp-json/';
	}

	getResource = async (url) => { 
		const res = await fetch(`${this._apiBase}${url}`); 
		if (!res.ok) {
			throw new Error(`Could not fetch ${url}; received ${res.status}`);
		}

		return await res.json();
	}

	getAllPages = async () => {
        const res = await this.getResource('contents/');
		return res;
	}

	getMenu = async () => {
		const res = await this.getResource('myroutes/menu/');
		return res;
	}
 
    getPost = async (page) => {
		//console.log(page);
		//page = page.replace("bear2", "sample-page");
		page = page.replace("/", "/pages?slug="); 
		//console.log(page);
		
		const pageLink='wp/v2'+page;
		const res = await this.getResource(pageLink); 
        return res;
    }


}