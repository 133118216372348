import React from "react"
import Hammer from "hammerjs"

export default class Swipe extends React.Component {
constructor(props) {
super(props)
this.state = {}
}
componentDidMount() {
    const viewerImage = document.getElementById("myElement");
    
    const hammertime = new Hammer(viewerImage);

 
    hammertime.on("panright", e => {
        document.querySelector('.container').classList.add("menuopen");
        //console.log("HAMMERTIME: pan right");
    })
    hammertime.on("panleft", e => {
        document.querySelector('.container').classList.remove("menuopen");
        //console.log("HAMMERTIME: pan left");
    })

}
    render() {
        return <div id="myElement">{this.props.children}</div>
    }
} 