import React from 'react';
import Menu from './menu';
import LoadPage from './loadPage';

import Cover from '../images/cover.png';
import introBg from '../images/introBg3.jpg';
import contentBg from '../images/bg.jpg';


export default function(Contents) {

    
    
    return (
        
        <div  style={{backgroundImage: `url(${contentBg})`}}>
            <div className="introBg" style={{backgroundImage: `url(${introBg})`}}>
                {/*<div className="introBg">*/}
            <div className="col7 cover">
                <img src={Cover} alt="Альбом «Медведь в истории культуры»" />
            </div>
            <div className="col5">
                
                <h1>Альбом «Медведь <br/>в истории культуры»</h1>
                <a href="https://www.senator-perm.ru/shop/books/perm/medved-v-istorii-kultury/" className="btn-red">Купить книгу</a> <nobr>или читать бесплатно онлайн:</nobr> 
                {/*<div className="switcher">
                    <h2>Читать бесплатно онлайн</h2>
                    <h2 class="switchOff"><a href="">Купить книгу</a></h2>
                </div>*/}
                
                <div className="menuBlock">
                    
                    
                    <Menu />
                </div>
                
                
            </div>

            
            <div className="introText">
                <LoadPage page="/main" header="false" />
                <footer>
                    <a href="https://www.senator-perm.ru/">Разработка и дизайн сайта: Имиджевый центр «<span>Сенатор</span>»</a>
                </footer>                
            </div>
            
            </div>
        </div>
    )
}