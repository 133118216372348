import React from 'react';


export default function PullQuoteBlock(params) {
    function createMarkup(htmlContent) {
         return {__html: htmlContent};
    };
    //console.log(params);


    return (
        <div className="blockEntry pullQuote">            
            <div className="text" dangerouslySetInnerHTML={createMarkup(params.text)} />
        </div>
    )
}