import React from 'react';

export default function ImageCoreBlock(params) {
    function createMarkup(htmlContent) {
         return {__html: htmlContent};
    };



    return (
        <div className="blockEntry imagecoreblock">
            
            <div dangerouslySetInnerHTML={createMarkup(params.params)} />
            

        </div>
    )
}