import React from 'react';


export default function MediaTextBlock(params) {
    function createMarkup(htmlContent) {
         return {__html: htmlContent};
    };
    //console.log(params);
    const blockClass="mediatext "+params.align;
    const contreplace = params.content.replace('<div class="wp-block-media-text__content"></div>', '<div class="wp-block-media-text__content">'+params.text+'</div>');

    return (
        <div className="blockEntry">            
            <div className={blockClass} dangerouslySetInnerHTML={createMarkup(contreplace)} />
        </div>
    )
}