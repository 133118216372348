import React, {Component} from 'react';
import TrackVisibility from 'react-on-screen';
import Block from './block';


export default class Blocks extends Component {
   
    render() {
        
        var idCount=0;

        const ComponentToTrack = ({ isVisible, params }) => {
            const visClass = isVisible ? 'showblock' : 'hideblock';            
            
            return <div className={visClass}><Block  params={params} /></div>;
        }

        
        
        return this.props.arr.map(item => {
            idCount++;

            return (
                <div key={"block"+idCount}>                       
                                <TrackVisibility  partialVisibility>
                                    <ComponentToTrack params={item} />
                                </TrackVisibility>
                </div>
            );

		});
    }

}

