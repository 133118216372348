import React from 'react';
import { SRLWrapper } from "simple-react-lightbox"; // Import SRLWrapper
import SimpleReactLightbox from "simple-react-lightbox"; // Import Simple React Lightbox




export default function GalleryBlock(params) {
    function createMarkup(htmlContent) {
         return {__html: htmlContent};
    };
    //console.log(params);
    const blockClass="gallery "+params.align;

    // Create an object with the options that we want to use
    const options = { 
        overlayColor: "rgba(0, 0, 0, .9)",
        captionColor: "#fff",
        captionFontSize: "1rem",
        captionFontWeight: "300",
        buttonsIconColor: "#db9d17",
        autoplaySpeed: 0,
        showThumbnails: false,
        transitionSpeed: 0,
        enablePanzoom: false,
        
    };

    let galleryoptions = {
        autoplaySpeed: 0
    };

    //console.log(galleryoptions);
    

    return (
        <div className="blockEntry">    
            <SimpleReactLightbox {...galleryoptions}>  
                <SRLWrapper options={options}>  
                    <div className={blockClass} dangerouslySetInnerHTML={createMarkup(params.text)} />
                </SRLWrapper>   
            </SimpleReactLightbox> 
        </div>
    )
}