import React from 'react';


export default function TextBlock(params) {
    function createMarkup(htmlContent) {
         return {__html: htmlContent};
    };
    //console.log(params);


    return (
        <div className="blockEntry authorQuote">            
            <div className="text" dangerouslySetInnerHTML={createMarkup(params.text)} />
            <div className="author" dangerouslySetInnerHTML={createMarkup(params.author)} />
        </div>
    )
}