import React from 'react';


export default function ImageLeft(params) {

    //console.log(params.image);
    function createMarkup(htmlContent) {
         return {__html: htmlContent};
    };




    //Functions for ASCII to JSON decoding https://r12a.github.io/app-conversion/
    
    function convertpEnc2Char ( str ) { 
        // converts a string containing precent encoded escapes to a string of characters
        // str: string, the input
        
        // find runs of hex numbers separated by % and send them for conversion
        str = str.replace(/((%[A-Fa-f0-9]{2})+)/g, 
                        function(matchstr, parens) {
                            //return convertpEsc2Char(parens.replace(/%/g,' ')); 
                            return convertpEsc2Char(parens)
                            }
                            )
        return str
        }
    
    function convertpEsc2Char ( str ) {
        // converts to characters a sequence of %-separated hex numbers representing bytes in utf8
        // str: string, the sequence to be converted
    
        var outputString = "";
        var counter = 0;
        
        
        var listArray = str.split('%');
        for ( var i = 1; i < listArray.length; i++ ) {
            var b = parseInt(listArray[i], 16);  // alert('b:'+dec2hex(b));
            switch (counter) {
            case 0:
                if (0 <= b && b <= 0x7F) {  // 0xxxxxxx
                    outputString += dec2char(b); } 


                break;
            default: break;
            }
            }
        return outputString;
        }
    
    function dec2char ( n ) {
        // converts a single string representing a decimal number to a character
        // note that no checking is performed to ensure that this is just a hex number, eg. no spaces etc
        // dec: string, the dec codepoint to be converted
        var result = '';
        if (n <= 0xFFFF) { result += String.fromCharCode(n); }  


        return result;
        }
    
    let imageBlock = JSON.parse(convertpEnc2Char(params.image));

    //End of decoding functions




    //console.log(imageBlock);
    //console.log(params.align);


    return (
        <div className={params.align}>
            <div className="image"><img src={imageBlock.url} alt="" /></div>
            <div className="imgcaption" dangerouslySetInnerHTML={createMarkup(params.text)} />
        </div>
    )
}