import React from 'react';


export default function SeparatorBlock(params) {

    //console.log(params);
    

    return (
        <div className="blockEntry">            
           <div className="separator">
                <div className="splline"></div>
                <div className="sprline"></div>
           </div>
        </div>
    )
}