import React from 'react';

//Убираем висящие предлоги
export default function Orphans(props) {

    const cortitle=props.children.replace(/\s+(в|без|до|из|к|на|по|о|от|перед|при|через|с|у|и|нет|за|над|для|об|под|про)+\s/ig, " $1\u00a0");
    return (
        <>
            {cortitle}
        </>
    );
}