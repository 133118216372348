import React from 'react';
import wave from '../images/wave2.gif';
import bgshadow from '../images/bg-shadow.png';

const NotFound = () => {
    return(
        <div className="notFound" style={{backgroundImage:`url(${wave})`}}>           
            
            <div className="mask" style={{backgroundImage:  `url(${bgshadow})`}}>
                
                <div className="centerText"><h1>Ошибка 404</h1><br/> Страница не найдена.<br/> Попробуйте перейти <a href="/">на главную</a>.</div>
            </div>
        </div>
    )
   
}

export default NotFound;