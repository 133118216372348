import React from 'react';
import LoadMenu from './loadMenu'

const Menu = () => {
	return (
		<div className="menu">
            <LoadMenu />
        </div>
	)
}

export default Menu;