import React from 'react';
import smallcover from '../images/teaser.jpg';

export default function Teaser() {
    const bookLink="https://www.senator-perm.ru/shop/books/perm/medved-v-istorii-kultury/";
    return (
        <div className="teaser">
            <a href={bookLink}>
                <img src={smallcover} alt="Купить книгу «Медведь в истории культуры»" />
            </a>
            <div>
                <a href={bookLink} className="btn-orange">
                    Купить книгу
                </a>
            </div>
            
        </div>
    )
    
}



